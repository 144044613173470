<template>
  <div>
    <form @submit.prevent="onsubmit" class="grid m-0 p-0 justify-content-center">
      <h3>Verificação de duas etapas</h3>
      <div class="p-fluid grid justify-content-center">

        <!-- <div class="mt-3 grid justify-content-center" v-if="emailSend == 0">
          <pm-ProgressSpinner style="width: 50px; height: 50px"></pm-ProgressSpinner>
        </div> -->

        <div class="mt-3 grid justify-content-center" v-if="emailSend == 1">
          <p class="m-0">Um código de verificação foi enviado para o seu email.</p>
          <label>Não esqueça de verificar na caixa de entrada, spam e lixeira.</label>
        </div>

        <div class="" v-if="emailSend == -1">
          <p id="verificationResultErro">Ocorreu um erro</p>
        </div>

        <div v-if="!isLoading" class="col-12 grid justify-content-center mt-5">
          <input
            v-for="(n, index) in 6"
            :key="index"
            type="text"
            v-model="otp[index]"
            @input="focusNext($event, index)"
            @paste.stop.prevent="handlePaste($event)"
            class="otp-input"
          />
        </div>
        <div v-else class="col-12 grid justify-content-center mt-5">
          <pm-ProgressSpinner></pm-ProgressSpinner>
        </div>


        <label v-if="!isLoading">Não recebeu o Código? <span @click="SendCode2aF" class="resend mt-4" for="resend">Reenviar código</span></label>
        <label v-else>Não recebeu o Código? <span class="resend mt-4" for="resend">Reenviar código</span></label>


        <!-- <div class="col-12 mt-3">
          <pm-Button v-if="!isLoading" label="Verificar"  @click="onsubmit" style="background: #014aad;border: 1px solid #014aad;" />
          <pm-Button v-else label="Verificar" disabled style="background: #014aad;border: 1px solid #014aad;" />
        </div> -->


      </div>
    </form>
    <!-- <div v-else>
      <p id="verificationResultErro">Acesso Negado!</p>
    </div> -->
    <p id="verificationResultErro" v-if="msgDisplay && !approved"> {{ msg }}</p>
  </div>
</template>

<script>
import { LoginInfo } from "@/class/login.js";
import axios from 'axios'
import { Calendario } from "@/class/calendario";
import { Configuracao } from "@/class/configuracao";
import router from "@/router";
  export default {
    name: 'Autenticacao2FA',
    props: {
      email: '',
      ano:0,
      situacao: 0,
      nome: ''
    },
    data () {
      return {
        isLoading: false,
        token: "",
        msgDisplay:0,
        msg:'',
        approved:0,
        emailSend: 0,
        codeReceived:0,
        otp: Array(6).fill(''),
        // ano:0
      }
    },
    async beforeMount(){
        if(this.email){
          // this.emailSend = 0
          await this.SendCode2aF()
          // await this.SendEmailHtml()
        }
    },
    methods: {
      handlePaste(e) {
        const paste = (e.clipboardData || window.clipboardData).getData('text');
        const pasteArray = paste.split('');

        if (pasteArray.length === this.otp.length) {
          this.otp = pasteArray;
          this.token = this.otp.join('');
          this.$nextTick(() => {
            this.$el.querySelectorAll('input')[5].focus();
          });
          if (this.token.length === 6) {
            this.onsubmit()
          }
        }
      },
      focusNext(e, index) {
        if (e.inputType === "deleteContentBackward" && index !== 0) {
          this.$el.querySelectorAll('input')[index - 1].focus();
        }else if (e.target.value.length === 1) {
          if (index !== 5) {
            this.$el.querySelectorAll('input')[index + 1].focus();
          }
          this.token = this.otp.join('');
          if (this.token.length === 6) {
            this.onsubmit()
          }
        }
      },
      async SendCode2aF () {
        try {
          this.isLoading = true;
          const data = await axios.post('https://i9bjl6axa5.execute-api.us-east-1.amazonaws.com/dev/secret');
          console.log('data',data.data)
          if (data.data) {
            this.codeReceived = data.data;
            await this.SendEmailHtml();
          }
          this.isLoading = false;
        }
        catch(e) {
          this.isLoading = false;
          console.log(e);
        }
      },

      async SendEmailHtml(){
        try {
          this.isLoading = true;
          const data = {
            email: this.email,
            codigo: this.codeReceived,
            nome: this.nome,
            // urlLogo: "",
            nomeApp: "SIME - SISTEMA DE MONITORAMENTO ESCOLAR ",
            secretaria: "SECRETARIA MUNICIPAL DE EDUCAÇÃO DE ITALVA",
            urlSite: "https://educacao.italva.rj.gov.br",
            frase1: "Recebemos uma solicitação para acessar sua Conta do Sistema de Monitoramento Escolar",
            frase2: "Se você não solicitou esse código, é possível que outra pessoa esteja tentando acessar sua conta no SIME - Sistema de Monitoramento Escolar da Secretaria Municipal de Educação de Italva",
            frase3: "Não encaminhe ou forneça esse código a ninguém. Caso você não tenha solicitado acesso ao sistema ignore essa mensagem e solicite suporte à Secretaria Municipal de Educação",
          }

          const envioEmail = await LoginInfo.sendEmailHtml(data)
          if (envioEmail) {
            this.emailSend = 1
            this.$vaToast.init({
            message: "Código de verificação enviado para o seu email",
            iconClass: 'fa-star-o',
            position: 'top-right',
            color:'success',
            duration: 3500,
            fullWidth: false,
          })
          }

          this.isLoading = false;
        }
        catch(e) {
          this.isLoading = false;
          this.emailSend= -1;
          console.log(e);
        }
      },

      async onsubmit () {
        try {
          const token = {
            token: this.token
          }
          this.isLoading = true;
          this.msgDisplay = 0;
          const data = await axios.post('https://i9bjl6axa5.execute-api.us-east-1.amazonaws.com/dev/verify', token)

          if (data.data) {
            this.msgDisplay = 1;
            if (data.data.isValid) {
              // this.msg = 'Autenticação Bem-Sucedida';
              this.approved = 1;
              this.redireciona();
            }else{
              this.msg = 'Código de verificação Inválido';
              this.approved = 0;
            }
          }
          this.isLoading = false;
        }
        catch(e) {
          this.isLoading = false;
          console.log(e);
        }
      },

      async redireciona(){
        const token = sessionStorage.getItem("token");
        const user = await LoginInfo.WhoIam(token);
        sessionStorage.setItem("funcaoDoUsuario", user.data.funcao);

        let anoEnvio;
        let anoSituacao;

        if (this.ano.ano == undefined) {
          anoEnvio = this.ano;
          anoSituacao = this.situacao;
        }
        else {
          anoEnvio = this.ano.ano;
          anoSituacao = this.ano.situacao
        }
        sessionStorage.setItem("anoSelecionado", anoEnvio);
        sessionStorage.setItem("anoSitucao", 0);

        const dt = await axios.get('/config/sistema');
        if(dt){
          sessionStorage.setItem("colorBar", dt.data.barralateral_cor);
          sessionStorage.setItem("colorBar_text", dt.data.barralateral_cortext);
          sessionStorage.setItem("colorDiaLetivo", dt.data.dia_letivo_cor);
          sessionStorage.setItem("presencaMarcada", dt.data.presenca_marcar.todas_marcadas);
        }

        if(user.data.funcao == 1 || user.data.funcao == 2 || user.data.funcao == 7){
          const data1 = await Calendario.verificarExisteCalendario();
          if (data1.data['error'] == "Não encontrado!") {
            this.$router.push({ name: 'calendario' });
          }
          else {
            const dt = await Configuracao.obtemOne(data1.data.id);
            if(dt.data.id){
              if(dt.data.autorizado == 1){

                // this.$router.push({ name: '2fa',params: { email:this.login }});
                // this.$router.push({ name: 'painel' });
                router.replace({
                  name: 'painel'
                })
              }else{
                router.replace({ name: 'calendario' });
              }
            }else{
                router.replace({ name: 'calendario' });
            }
          }
        }
        else if(user.data.funcao == 5 ){
          if(user.data.diretor == 0){
            router.replace({ name: 'painel-professor' });
          }else{
            sessionStorage.setItem("coordenacao",1);
            router.replace({ name: 'coordenacao-escola' });
          }
        }
        else if(user.data.funcao == 4){
          sessionStorage.setItem("coordenacao",2);
          router.replace({ name: 'coordenacao-escola' });
        }
          else if(user.data.funcao == 3){
            sessionStorage.setItem("coordenacao",1);
          router.replace({ name: 'coordenacao-escola' });
        }
        /*if(user.permissao.funcao_id == "6" ){
            this.$router.push({ name: 'escolas' });
        }*/
        //termina aqui"
      }

    }
  }
</script>

<style>
.otp-input {
  width: 40px;
  height: 40px;
  padding: 5px;
  margin: 0 10px;
  font-size: 20px;
  border-radius: 4px;
  border: 1px solid rgba(0,0,0,0.3);
  text-align: center;
}

#verificationResult1 {
    margin-top: 15px;
    font-weight: bold;
    color: #1d1d1d;
    background-color: #c3cf52;
    border-color: #c3cf52;
    padding: 1rem;
    border-radius:5px;
}

#verificationResultErro {
    margin-top: 15px;
    font-weight: bold;
    color: #f7f7f7;
    background-color: #ff4539;
    border-color: #ff4539;
    padding: 1rem;
    border-radius:5px;
}

#verificationResult {
    margin-top: 15px;
    font-weight: bold;
    color: #0c5460;
    background-color: #d1ecf1;
    border-color: #bee5eb;
    padding: 1rem;
    border-radius:5px;
}

.resend{
  color: #2f00ff;
}
.resend:hover{
  color: #1a008b;
  cursor: pointer;
}
</style>
